<template>
  <div class="sidebar">
    <el-menu
        class="sidebar-el-menu"
        :default-active="onRoutes"
        :collapse="collapse"
        background-color="#324157"
        text-color="#bfcbd9"
        active-text-color="#20a0ff"
        unique-opened
        router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                  v-if="subItem.subs"
                  :index="subItem.index"
                  :key="subItem.index"
              >
                <template #title>{{ subItem.title }}</template>
                <el-menu-item
                    v-for="(threeItem, i) in subItem.subs"
                    :key="i"
                    :index="threeItem.index"
                >{{ threeItem.title }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                  v-else
                  :index="subItem.index"
                  :key="subItem.index"
              >{{ subItem.title }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
// import bus from "../common/bus";
export default {
  data() {
    return {
      items: [
        // {
        //     icon: "el-icon-lx-home",
        //     index: "dashboard",
        //     title: "系统首页"
        // },
        // {
        //   icon: "el-icon-lx-news",
        //   index: "news",
        //   title: "新闻"
        // },
        // {
        //   icon: "el-icon-lx-info",
        //   index: "tips",
        //   title: "提示"
        // },
        // {
        //   icon: "el-icon-lx-cascades",
        //   index: "carrier",
        //   title: "载体"
        // },
        // {
        //   icon: "el-icon-lx-service",
        //   index: "attract",
        //   title: "招商"
        // },
        // {
        //   icon: "el-icon-lx-home",
        //   index: "realty",
        //   title: "物业"
        // },
        // {
        //   icon: "el-icon-lx-group",
        //   index: "company",
        //   title: "企业"
        // },
        // {
        //   icon: "el-icon-lx-profile",
        //   index: "admin",
        //   title: "员工"
        // },
        // {
        //   icon: "el-icon-lx-comment",
        //   index: "opinion",
        //   title: "建议"
        // },
        // {
        //   icon: "el-icon-lx-cascades",
        //   index: "editor",
        //   title: "富文本"
        // },
        // {
        //   icon: "el-icon-lx-cascades",
        //   index: "victor",
        //   title: "victor"
        // },
      ]
    };
  },
  created() {
    if (this.items.length == 0) {
      let that = this
      let memu = JSON.parse(localStorage.getItem('memu'))
      if (memu == null) {
        return
      }
      memu.forEach(function (value) {
        that.items.push({index:value.path, title:value.name, icon:value.icon})
      })
    }

  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.collapse
    }
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}

.sidebar > ul {
  height: 100%;
}
</style>
