import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/news'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import (
                    "../views/NewsTable.vue")
            }, {
                path: "/news",
                name: "newstable",
                meta: {
                    title: '新闻'
                },
                component: () => import (
                    "../views/NewsTable.vue")
            },
            {
                path: "/tips",
                name: "tipstable",
                meta: {
                    title: '提示'
                },
                component: () => import (
                    "../views/TipsTable.vue")
            },
            {
                path: "/carrier",
                name: "carriertable",
                meta: {
                    title: '载体'
                },
                component: () => import (
                    "../views/CarrierTable.vue")
            },
            {
                path: "/attract",
                name: "attracttable",
                meta: {
                    title: '招商'
                },
                component: () => import (
                    "../views/AttractTable.vue")
            },
            {
                path: "/realty",
                name: "realtytable",
                meta: {
                    title: '物业'
                },
                component: () => import (
                    /* webpackChunkName: "tips" */
                    "../views/RealtyTable.vue")
            },
            {
                path: "/company",
                name: "companytable",
                meta: {
                    title: '企业'
                },
                component: () => import (
                    /* webpackChunkName: "tips" */
                    "../views/CompanyTable.vue")
            },
            {
                path: "/admin",
                name: "admintable",
                meta: {
                    title: '员工'
                },
                component: () => import (
                    /* webpackChunkName: "tips" */
                    "../views/AdminTable.vue")
            },
            {
                path: "/opinion",
                name: "opiniontable",
                meta: {
                    title: '建议'
                },
                component: () => import (
                    /* webpackChunkName: "tips" */
                    "../views/OpinionTable.vue")
            },
            {
                path: "/dict",
                name: "dicttable",
                meta: {
                    title: '配置'
                },
                component: () => import (
                    /* webpackChunkName: "tips" */
                    "../views/DictTable.vue")
            },
            {
                path: "/tinymce",
                name: "tinymcetable",
                meta: {
                    title: 'tinymce'
                },
                component: () => import (
                    "../views/Tinymce.vue")
            },
            {
                path: "/permission",
                name: "permission",
                meta: {
                    title: '权限管理',
                    permission: true
                },
                component: () => import (
                    /* webpackChunkName: "permission" */
                    "../views/Permission.vue")
            },
            {
                path: "/i18n",
                name: "i18n",
                meta: {
                    title: '国际化语言'
                },
                component: () => import (
                    /* webpackChunkName: "i18n" */
                    "../views/I18n.vue")
            },
            {
                path: "/upload",
                name: "upload",
                meta: {
                    title: '上传插件'
                },
                component: () => import (
                    /* webpackChunkName: "upload" */
                    "../views/Upload.vue")
            },
            {
                path: "/icon",
                name: "icon",
                meta: {
                    title: '自定义图标'
                },
                component: () => import (
                    /* webpackChunkName: "icon" */
                    "../views/Icon.vue")
            },
            {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import (/* webpackChunkName: "404" */
                    '../views/404.vue')
            },
            {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import (/* webpackChunkName: "403" */
                    '../views/403.vue')
            }
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
            /* webpackChunkName: "login" */
            "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// router.beforeEach((to, from, next) => {
//     document.title = `${to.meta.title}`;
//     const role = localStorage.getItem('ms_username');
//     if (!role && to.path !== '/login') {
//         next('/login');
//     } else if (to.meta.permission) {
//         // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
//         role === 'admin'
//             ? next()
//             : next('/403');
//     } else {
//         next();
//     }
// });

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    // const role = localStorage.getItem('ms_username');
    // if (!role && to.path !== '/login') {
    //     next('/login');
    // } else if (to.meta.permission) {
    //     // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    //     role === 'admin'
    //         ? next()
    //         : next('/403');
    // } else {
    //     next();
    // }
    const whiteList = ['/403', '/404']

    let memu = JSON.parse(localStorage.getItem('memu'))
    let token = localStorage.getItem('token')
    if(to.path === '/login') {
        next()
    }else if (!token && to.path !== '/login'){
        next('/login')
    }else if (whiteList.indexOf(to.path) !== -1 ){
        next()
    }

    let menuRoutes = {}
    memu.forEach(function(value){
        menuRoutes['/'+value.path] = '/'+value.path
    })
    if(menuRoutes[to.path] !== to.path) {
        next('/403')
    }else {
        next()
    }
});

export default router;
